import Request from '@hotmart/request';
import https from 'https';
import { isSeasonActive } from 'utils/campaigns';
import { capitalizeLocale } from 'utils/language';
import { getParamsUser } from 'utils/string';
import { QUERY_IDS } from '@core/data/constants/query-ids';
import config from '../../env.config';
import { Constants } from './request';

import { seasonInfo } from '../constants';

export function verifyIsoLanguage(language? : string) {
  if (!language) return undefined;
  language = language.toString().toLowerCase() as Language;

  if (language.match(/^pt$|^pt-pt$/)) {
    return 'pt';
  }
  if (language.match(/^pt-br$|^pt_br$/)) {
    return 'pt_br';
  }
  if (language.match(/^it$|^de$|^ru$|^ar$|^ja$/)) {
    return 'en';
  }

  return language;
}

class SearchService {
  shouldUseQualityScoreQueries = false;

  getQualityScoreQueryId = (queryId = '') => {
    if (this.shouldUseQualityScoreQueries) {
      if (queryId === QUERY_IDS.MAIN_QUERY) {
        return QUERY_IDS.QUALITY_SCORE_MAIN;
      }

      return QUERY_IDS.QUALITY_SCORE_FACETS;
    }

    return queryId;
  };

  async getResults(searchParams, internal, useQualityScoreQueries) : Promise<RequestGetResultsParams> {
    const {
      searchText,
      size = 20,
      searchAfterScore,
      searchAfterProductId,
      formats,
      optionsPayment,
      categories,
      topifications,
      topics,
      ratings,
      languages,
      coupons,
      excludeFilterForCategory,
      discounts,
      couponType,
      groupKeys,
      campaigns,
      offset = 0,
      limit = 24,
      lng,
      sort,
      queryId = QUERY_IDS.PRINCIPAL_QUERY
    } = searchParams;
    this.shouldUseQualityScoreQueries = useQualityScoreQueries;

    try {
      const params = {
        searchText,
        size,
        searchAfterScore: undefined,
        searchAfterProductId: undefined,
        formats: undefined,
        optionsPayment: undefined,
        categories: undefined,
        topifications: undefined,
        topics: undefined,
        ratings: undefined,
        languages: undefined,
        limit: undefined,
        discounts: undefined,
        couponType: undefined,
        excludeFilterForCategory: undefined,
        offset: undefined,
        groupKeys: undefined,
        campaigns: undefined,
        language: undefined,
        sort: undefined,
        queryId: undefined
      };
      const endpoint = Constants.RESULTS;

      if (searchAfterScore) {
        params.searchAfterScore = searchAfterScore;
      }
      if (searchAfterProductId) {
        params.searchAfterProductId = searchAfterProductId;
      }
      if (formats) {
        params.formats = formats;
      }
      if (optionsPayment) {
        params.optionsPayment = optionsPayment;
      }
      if (categories) {
        params.categories = categories;
      }
      if (topifications) {
        params.topifications = topifications;
      }
      if (topics) {
        params.topics = topics;
      }
      if (ratings) {
        params.ratings = ratings;
      }
      if (languages) {
        params.languages = languages;
      }
      if (limit) {
        params.limit = limit;
      }
      if (coupons) {
        params.discounts = coupons;
        if (isSeasonActive(lng)) {
          params.couponType = seasonInfo.COUPON_TYPE;
        }
      }
      if (excludeFilterForCategory) {
        params.excludeFilterForCategory = excludeFilterForCategory;
      }

      params.offset = offset || 0;

      if (discounts) {
        params.discounts = discounts;
        if (isSeasonActive(lng)) {
          params.couponType = seasonInfo.COUPON_TYPE;
        }
      }
      if (couponType) {
        params.couponType = couponType;
      }

      if (groupKeys) {
        params.groupKeys = groupKeys;
      }

      if (campaigns) {
        params.campaigns = campaigns;
      }

      if (lng) {
        params.language = `${lng}`.toLowerCase();
        if (lng === 'pt') {
          params.language = 'pt-br';
        }
      }

      if (sort) {
        params.sort = sort || '';
      }

      if (queryId) {
        params.queryId = this.getQualityScoreQueryId(queryId);
      }

      if (size) {
        params.size = size;
      }

      if ( internal ) {
        const endpointInternal =  Constants.RESULTS_INTERNAL;

        // At request level pass https agent to axios request
        const agent = new https.Agent({
          rejectUnauthorized: false
        });
        return Request(endpointInternal, params, { httpsAgent: agent });
      }
      return Request(endpoint, params);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  getResultsGroupByCategoryCount = (searchParams, internal) => {
    const { searchText, formats, optionsPayment, couponType, coupons, groupKeys, sort, ratings, languages } = searchParams;

    try {
      const params = {
        searchText,
        size: 1,
        couponType,
        discounts: undefined,
        formats: undefined,
        optionsPayment: undefined,
        groupKeys: undefined,
        ratings: undefined,
        languages: undefined,
        sort: undefined
      };

      if (coupons) {
        params.discounts = coupons;
        params.couponType = seasonInfo.COUPON_TYPE;
      }

      if (formats) {
        params.formats = formats;
      }

      if (optionsPayment) {
        params.optionsPayment = optionsPayment;
      }

      if (groupKeys) {
        params.groupKeys = groupKeys;
      }

      if (ratings) {
        params.ratings = ratings;
      }


      if (languages) {
        params.languages = languages;
      }

      if (sort) {
        params.sort = sort || '';
      }

      if (internal) {
        // At request level pass https agent to axios request
        const agent = new https.Agent({
          rejectUnauthorized: false
        });
        return Request(Constants.SEARCH_GROUPBY_CATEGORIES_INTERNAL, params, { httpsAgent: agent });
      }
      return Request(Constants.SEARCH_GROUPBY_CATEGORIES, params);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  getBestSellers = async searchParams => {
    const { size, language } = searchParams;
    try {
      const params = {
        size: size || 8,
        language: verifyIsoLanguage(language),
        queryId: QUERY_IDS.BEST_PRODUCT_RANDOM
      };

      return Request(Constants.RESULTS, params);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getRecommendation = async searchParams => {
    const { language, internal } = searchParams;
    const locale = capitalizeLocale(language);
    const maxTimeout = 800;

    try {
      const params = {
        locale: locale || 'PT_BR',
        blacknovember: searchParams?.blacknovember || false
      };

      if (internal) {
        const agent = new https.Agent({
          rejectUnauthorized: false
        });
        return Request(Constants.PRODUCTS_RECOMMENDATION_INTERNAL, params, { httpsAgent: agent,  timeout: maxTimeout });
      }

      return Request(Constants.PRODUCTS_RECOMMENDATION, params, { timeout: maxTimeout });
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getFeatureSwitchInternal = async (internal?: boolean) => {
    try {
      const agent = new https.Agent({
        rejectUnauthorized: false
      });
      const maxTimeout = internal ? 800 : 2000;

      return Request(Constants.FEATURES_SWITCH_INTERNAL, {}, { httpsAgent: agent,  timeout: maxTimeout  });
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getCategoriesBestSellers = async (searchParams) => {
    const { size, language, categories, cookies, topifications, topics } = searchParams;
    const { ga = null, hotid = null } = cookies;

    try {
      const params = {
        size: size || 8,
        language: verifyIsoLanguage(language),
        categories,
        topifications,
        topics,
        ...(ga ? { ga } : {}),
        ...(!ga && hotid ? { hotid } : {})
      };
      return Request(Constants.BEST_SELLERS_RANDOM, params);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getTopSales = async (searchParams) => {
    const { language } = searchParams;

    try {
      const params = { language: verifyIsoLanguage(language) };
      return Request(Constants.TOP_SALES, params);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getFavoritesTags = async (searchParams) => {
    const { size, tags } = searchParams;

    try {
      const params = { size: size || 12, favoriteTerms: tags };
      return Request(Constants.RESULTS_FAVORITES, params);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getRandomCampaign = async searchParams => {
    const { size, groupKeys } = searchParams;
    try {
      const params = { size: size || 4, groupKeys };

      return Request(Constants.RANDOM_CAMPAIGN, params);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getInfoCampaign = async (slug) => {
    try {
      return Request(Constants.INFO_CAMPAIGN, slug);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getStatusCampaign = async () => {
    try {
      return Request(Constants.STATUS_CAMPAIGN);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  getBannerCampaign = async () => {
    try {
      return Request(Constants.BANNER_CAMPAIGN);
    } catch (e) {
      console.log('e:', e);
      return undefined;
    }
  };

  loadResultsProduct = async ({
    internal,
    categories,
    topifications,
    topics,
    formats,
    optionsPayment,
    coupons,
    loadGroupByCategory,
    groupKeys,
    campaigns,
    ratings,
    languages,
    offset = 0,
    showSearchLTR,
    discounts,
    useQualityScoreQueries = false,
    allCouponsSelected = null,
    ...searchParams
  } : RequestLoadResultsParams) => {
    const finalSearchParams = {
      size: config.envConfig.RESULTS.QTY_PER_PAGE,
      searchText: null,
      coupons: (Array.isArray(coupons) && coupons.join(',')) || '',
      groupKeys: groupKeys ? ((Array.isArray(groupKeys) && groupKeys.join(',')) || groupKeys) : '',
      campaigns,
      categories: (Array.isArray(categories) && categories.join(',')) || '',
      formats: (Array.isArray(formats) && formats.join(',')) || '',
      optionsPayment: (Array.isArray(optionsPayment) && optionsPayment.join(',')) || '',
      ratings: (Array.isArray(ratings) && ratings.join(',')) || '',
      topifications: (Array.isArray(topifications) && topifications.join(',')) || '',
      topics: (Array.isArray(topics) && topics.join(',')) || '',
      languages: (Array.isArray(languages) && languages.join(',')) || '',
      lng: 'en',
      discounts: (Array.isArray(allCouponsSelected) && allCouponsSelected.join(',')) || (Array.isArray(discounts) && discounts.join(',')) || '',
      offset,
      ...searchParams
    };

    let requestData = null;
    const requestDataGroupByCategory = null;

    try {
      requestData = await this.getResults(finalSearchParams, internal, useQualityScoreQueries);
    } catch (err) {
      console.error('err', err);
    }

    const result = {
      requestDataGroupByCategory: undefined,
      ...finalSearchParams,
      discounts: allCouponsSelected ? [] : finalSearchParams?.discounts,
      categories,
      topifications,
      topics,
      formats,
      optionsPayment,
      coupons,
      ratings,
      languages,
      requestData
    };
    // adiciona a lista de quantidade de produtos por categoria apenas se houver um valor assim não sobrescreveremos o seu valor inicial quando voltar o resultado pro contexto
    if (loadGroupByCategory && requestDataGroupByCategory) {
      result.requestDataGroupByCategory = requestDataGroupByCategory;
    }

    return result;
  };

  async getGroupByCategories(searchParams) {
    const { searchText, size = 20, categories, lng } = searchParams;
    const paramsUser = getParamsUser();

    const params = { searchText, size, categories,
      language: undefined,
      ...paramsUser
    };

    if (lng) {
      params.language = `${lng}`.toLowerCase();
      if (lng === 'pt') {
        params.language = 'pt-br';
      }
    }

    try {
      return Request(Constants.RESULTS_BY_CATEGORIES, params);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getHighlightedDisplays(term) {
    try {
      return Request(Constants.DISPLAYS_HIGHLIGHTED, { term });
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  getProductNewbies = searchParams => {
    const { size = 8, categories, lng, cookies, topifications, topics } = searchParams;
    const { ga = null, hotid = null } = cookies;

    try {
      const params = {
        size,
        categories: undefined,
        language: undefined,
        topifications: undefined,
        topics: undefined,
        queryId: undefined,
        ...(ga ? { ga } : {}),
        ...(!ga && hotid ? { hotid } : {})
      };

      if (categories) {
        params.categories = categories;
      }
      if (topifications) {
        params.topifications = topifications;
      }
      if (topics) {
        params.topics = topics;
      }

      if (lng) {
        params.language = `${lng}`.toLowerCase();
        if (lng === 'pt') {
          params.language = 'pt-br';
        }
      }

      params.queryId = QUERY_IDS.NEWBIES;

      return Request(Constants.RESULTS, params);
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  getProductsByIds = productIds => {
    try {
      return Request(Constants.PRODUCT_IDS, {
        productIds: productIds.join(',')
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  getProductsMapById = async productsIds => {
    try {
      const products = await this.getProductsByIds(productsIds);
      const productsMap = {};
      if (products && products.length > 0) {
        products.forEach(product => {
          productsMap[product.productId] = product;
        });
      }
      return productsMap;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  async getCategories(term) {
    try {
      return Request(Constants.CATEGORIES, { term });
    } catch (e) {
      return {};
    }
  }

  async getUser() {
    try {
      const user = await Request(Constants.USER);
      return user;
    } catch (e) {
      return {};
    }
  }

  async checkToken(token) {
    try {
      const user = await Request(Constants.CHECKTOKEN, { token });
      return user;
    } catch (e) {
      return undefined;
    }
  }

  async logout(accessToken) {
    try {
      const logout = await Request(Constants.LOGOUT, { access_token: accessToken });
      return logout;
    } catch (e) {
      return {};
    }
  }

  async getCheckoutPrice(products) {
    try {
      const prices = await Request(Constants.PRICE, { paymentRequest: products, attach_token: false });
      return prices;
    } catch (e) {
      return e;
    }
  }

  async getFeatureSwitch() {
    try {
      const featureSwitchResponse = await Request(Constants.FEATURES_SWITCH);
      const featureSwitch = {};
      // convertendo dados para Object
      if (featureSwitchResponse && Array.isArray(featureSwitchResponse)) {
        featureSwitchResponse.forEach((feature) => {
          if (
            feature &&
            Object.hasOwnProperty.call(feature, 'key') &&
            Object.hasOwnProperty.call(feature, 'value')
          ) {
            featureSwitch[feature.key] = feature.value;
          }
        });
      }
      return featureSwitch;
    } catch (e) {
      return undefined;
    }
  }

  async getFiltersCount(params, useQualityScoreQueries = false) {
    const paramsUser = getParamsUser();
    this.shouldUseQualityScoreQueries = useQualityScoreQueries;

    try {
      const coupons = await Request(Constants.GET_FILTERS_COUNT, {
        ...params,
        discounts: params?.discounts?.length > 0 ? params?.discounts.join() : null,
        optionsPayment: params?.optionsPayment?.length > 0 ? params?.optionsPayment.join() : null,
        categories: params?.categories?.length > 0 ? params?.categories.join() : null,
        topics: params?.topics?.length > 0 ? params?.topics.join() : null,
        formats: params?.formats?.length > 0 ? params?.formats.join() : null,
        queryId: this.getQualityScoreQueryId() || null,
        ...paramsUser
      });

      return coupons;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getProductsById(ids) {
    try {
      const idsJoined = Array.isArray(ids) && ids.join(',');
      const products = await Request(Constants.GET_BY_ID, { productsIds: idsJoined });

      return products;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

export default new SearchService();
