export const FIRE_2023_DATA = {
  'sections': [
    {
      'screen': 'CATEGORY_PAGE',
      'name': 'BANNER_TOP',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'objectKey': '85898437-cc30-4574-bd2b-f7fbb20597f7',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/85898437-cc30-4574-bd2b-f7fbb20597f7/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/HuoSlGLeDGS6V9v2vuIULwlGwUA=/1246x135/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/85898437-cc30-4574-bd2b-f7fbb20597f7/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'objectKey': 'b947998c-ce86-412a-8a91-f2f9aaef7f28',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/b947998c-ce86-412a-8a91-f2f9aaef7f28/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'urlFinal': 'https://static-media.hotmart.com/0wk-Huft6dcfx1HEFHgbccpM_7I=/260x35/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/b947998c-ce86-412a-8a91-f2f9aaef7f28/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro do mercado digital.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023/V73717070O?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_traffic_fire_banner_category_page&SCK=HOTMART_SITE&SRC=HOTMART_fire'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_180x25px.png',
            'objectKey': '9f955553-c7ad-4ee5-9520-9bdd16372041',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/9f955553-c7ad-4ee5-9520-9bdd16372041/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_180x25px.png',
            'urlFinal': 'https://static-media.hotmart.com/UNTeXzLU1O033zq5sc666WymAYQ=/180x25/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/9f955553-c7ad-4ee5-9520-9bdd16372041/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_180x25px.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x222px.png',
            'objectKey': '7f0e83cd-a717-42af-92cf-c2a49de4a28e',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/7f0e83cd-a717-42af-92cf-c2a49de4a28e/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x222px.png',
            'urlFinal': 'https://static-media.hotmart.com/6tCl1WKh2Jb71IC0OW9G5V-9P24=/374x174/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/7f0e83cd-a717-42af-92cf-c2a49de4a28e/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x222px.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro do mercado digital.'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023/V73717070O?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_traffic_fire_banner_category_page&SCK=HOTMART_SITE&SRC=HOTMART_fire'
          }
        }
      ],
      'country': 'brazil',
      'url': 'fire-festival-2023'
    },
    {
      'screen': 'HOME',
      'name': 'BANNER_TOP',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'objectKey': 'daee2305-0f8e-480b-8f14-8b616040d1a0',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/daee2305-0f8e-480b-8f14-8b616040d1a0/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/y4EYeMLyX_qMaeaUGv0vOos_Eb4=/1246x288/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/daee2305-0f8e-480b-8f14-8b616040d1a0/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'objectKey': 'b0dd8364-f13b-424c-8fbd-12937b17d4b6',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/b0dd8364-f13b-424c-8fbd-12937b17d4b6/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'urlFinal': 'https://static-media.hotmart.com/hL7CBz2sbv-lxtgVChByczEJakY=/200x35/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/b0dd8364-f13b-424c-8fbd-12937b17d4b6/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro de tudo que já aconteceu e vai acontecer no mercado digital.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_sales_fire_banner_depoimento-venda'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'objectKey': 'e8ab991d-fa8e-4e4d-9aa2-f3490769819b',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/e8ab991d-fa8e-4e4d-9aa2-f3490769819b/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/VRqN0RvCRa4E8betlflSIjSS0lQ=/374x214/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/e8ab991d-fa8e-4e4d-9aa2-f3490769819b/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'objectKey': 'acb332a1-60d0-46a5-b864-8b802d112249',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/acb332a1-60d0-46a5-b864-8b802d112249/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'urlFinal': 'https://static-media.hotmart.com/mhA8OlXQ86uGmwdclnRQ0pGNifI=/180x25/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/acb332a1-60d0-46a5-b864-8b802d112249/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_sales_fire_banner_depoimento-venda'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro de tudo que já aconteceu e vai acontecer no mercado digital.'
          }
        }
      ],
      'country': 'brazil',
      'url': 'fire-festival-2023'
    },
    {
      'screen': 'HOME',
      'name': 'BANNER_MIDDLE',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro de tudo que já aconteceu e vai acontecer no mercado digital.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'objectKey': '3bf663bd-1121-4cde-89b5-2abeaac87013',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/3bf663bd-1121-4cde-89b5-2abeaac87013/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'urlFinal': 'https://static-media.hotmart.com/2PlHIfBYuk7YzgN6uRcLCTIJMZM=/200x35/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/3bf663bd-1121-4cde-89b5-2abeaac87013/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'objectKey': '608b0f43-f902-47cf-8003-7c03e192b87a',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/608b0f43-f902-47cf-8003-7c03e192b87a/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/Q6bk_nbGT6Gi5A3nHhXD_8kBhsw=/374x214/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/608b0f43-f902-47cf-8003-7c03e192b87a/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro de tudo que já aconteceu e vai acontecer no mercado digital.'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'objectKey': '568fa27a-364f-45b3-b12f-a3f5234c1b36',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/568fa27a-364f-45b3-b12f-a3f5234c1b36/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png',
            'urlFinal': 'https://static-media.hotmart.com/3K60FSbTKD1zwEVKeIJZcdRm_ck=/180x25/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/568fa27a-364f-45b3-b12f-a3f5234c1b36/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'objectKey': 'b522daac-356e-4516-84d7-64b0f15dd3d3',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/b522daac-356e-4516-84d7-64b0f15dd3d3/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/FpHJGcTqJZgeVoTzynMZ2qgynBQ=/1246x288/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/b522daac-356e-4516-84d7-64b0f15dd3d3/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=hotmart&itm_campaign=pt-br_sales_fire_banner_fire-home-hotmart'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=hotmart&itm_campaign=pt-br_sales_fire_banner_fire-home-hotmart'
          }
        }
      ],
      'country': 'brazil',
      'url': 'fire-festival-2023'
    },
    {
      'screen': 'MENU',
      'name': 'BANNER_TOP',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png',
            'objectKey': '818246e2-5e5a-4686-96c5-d9f54c183ab3',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/818246e2-5e5a-4686-96c5-d9f54c183ab3/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png',
            'urlFinal': 'https://static-media.hotmart.com/KXLKECa5LbZe8h2x_lMYianR198=/289x85/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/818246e2-5e5a-4686-96c5-d9f54c183ab3/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=hotmart&itm_campaign=pt-br_sales_fire_banner_fire-home-hotmart'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png',
            'objectKey': '4b1bf7c9-3b2c-49de-b8e8-0448a370a5af',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/4b1bf7c9-3b2c-49de-b8e8-0448a370a5af/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png',
            'urlFinal': 'https://static-media.hotmart.com/CLU8UxK0DYXC3thcbCb7xtYH7M0=/166x22/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/4b1bf7c9-3b2c-49de-b8e8-0448a370a5af/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#ea0a8c'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png',
            'objectKey': '5a229070-2fde-4de6-8572-93ea449b86e6',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/5a229070-2fde-4de6-8572-93ea449b86e6/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png',
            'urlFinal': 'https://static-media.hotmart.com/N6U7J_xJ-gpTpFVM-BLZcLvNnyw=/289x85/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/5a229070-2fde-4de6-8572-93ea449b86e6/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_289x85px.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023?itm_medium=internal&itm_source=hotmart&itm_campaign=pt-br_sales_fire_banner_fire-home-hotmart'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#ea0a8c'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png',
            'objectKey': 'f02667b7-f77e-4c8c-b6a4-52dc387058ef',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/f02667b7-f77e-4c8c-b6a4-52dc387058ef/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png',
            'urlFinal': 'https://static-media.hotmart.com/gnNAh4dsWr2RKfIWylYaFCD7kYI=/166x22/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/f02667b7-f77e-4c8c-b6a4-52dc387058ef/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_166x22px.png'
          }
        }
      ],
      'country': 'brazil',
      'url': 'fire-festival-2023'
    },
    {
      'screen': 'SEARCH_RESULT_PAGE',
      'name': 'BANNER_TOP',
      'components': [
        {
          'deviceType': 'WEB',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'objectKey': '385a9c1d-a2ef-49cf-a919-9a215748a810',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/385a9c1d-a2ef-49cf-a919-9a215748a810/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/yBPnVz5JlIbHepGxMQY5AxpJEhA=/1246x135/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/385a9c1d-a2ef-49cf-a919-9a215748a810/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_1246x288px.jpg'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'objectKey': '7e6de1fa-044d-43f5-89bd-4876a7973783',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/7e6de1fa-044d-43f5-89bd-4876a7973783/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'urlFinal': 'https://static-media.hotmart.com/wiY4NdG6z2yDjMMwpxFbXibbzAA=/260x35/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/7e6de1fa-044d-43f5-89bd-4876a7973783/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro do mercado digital.'
          }
        },
        {
          'deviceType': 'WEB',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023/V73717070O?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_traffic_fire_banner_search_page&SCK=HOTMART_SITE&SRC=HOTMART_fire'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'BACKGROUND_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'objectKey': '551734ab-256e-4b60-b61b-89926d3f34ce',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/551734ab-256e-4b60-b61b-89926d3f34ce/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg',
            'urlFinal': 'https://static-media.hotmart.com/y1BkuH8E1MUY57Fe9ZUEUN8S3Ek=/374x174/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/551734ab-256e-4b60-b61b-89926d3f34ce/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_MOBILE_374x214px.jpg'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'LOGO_IMAGE',
          'type': 'IMAGE',
          'attributes': {
            'fileName': 'FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'objectKey': 'a47d3244-f229-409d-8ca0-f52777b0a086',
            'url': 'https://dsp-cdn.hotmart.com/campaign/media/h7nf3/a47d3244-f229-409d-8ca0-f52777b0a086/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png',
            'urlFinal': 'https://static-media.hotmart.com/Zwo3cEtnOeh49SCpzTZBg3N94oE=/180x25/smart/filters:format(webp)/dsp-campaign.hotmart.com/campaign/media/h7nf3/a47d3244-f229-409d-8ca0-f52777b0a086/FIRE23_CAMPANHA-ACQ_BANNERS_MARKETPLACE_DESKTOP_LOGO_200x35px-1.png'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA',
          'type': 'TEXT',
          'attributes': {
            'value': 'QUERO PARTICIPAR',
            'ctaBtnColor': '#f15925'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'TEXT',
          'type': 'TEXT',
          'attributes': {
            'value': 'O evento obrigatório para quem quer ficar por dentro do mercado digital.'
          }
        },
        {
          'deviceType': 'MOBILE',
          'name': 'CTA_URL',
          'type': 'TEXT',
          'attributes': {
            'value': 'https://hotmart.com/pt-br/marketplace/produtos/fire-festival-2023/V73717070O?itm_medium=internal&itm_source=mktplace&itm_campaign=pt-br_traffic_fire_banner_search_page&SCK=HOTMART_SITE&SRC=HOTMART_fire'
          }
        }
      ],
      'country': 'brazil',
      'url': 'fire-festival-2023'
    }
  ],
  date: {
    START_DATE: '2023/08/01 22:00:00 GMT-0300',
    END_DATE: '2023/08/22 23:59:59 GMT-0300'
  }
};
