/* Cosmos global styles */
import '@cosmos/styles/reset.css';
import '@cosmos/styles/base.css';
import '@cosmos/styles/theme.css';

/* Cosmos global components */
import '@cosmos/button/button.css';
import '@cosmos/button/variations/primary.css';
import '@cosmos/button/variations/custom.css';
import '@cosmos/button/variations/secondary.css';
import '@cosmos/button/variations/tertiary.css';
import '@cosmos/button-group/button-group.css';
import '@cosmos/button/variations/small.css';
import '@cosmos/grid/grid.css';

/* Cosmos global utilities */
import '@cosmos/styles/utilities/sizing/sizing.css';
import '@cosmos/styles/utilities/shadow.css';
import '@cosmos/styles/utilities/position.css';
import '@cosmos/styles/utilities/text/text.css';
import '@cosmos/styles/utilities/border/border.css';
import '@cosmos/styles/utilities/border/variations/grays.css';
import '@cosmos/styles/utilities/scroll.css';
import '@cosmos/styles/utilities/cursor.css';
import '@cosmos/styles/utilities/spacing/spacing.css';
import '@cosmos/styles/utilities/display/display.css';
import '@cosmos/styles/utilities/flex/flex.css';
import '@cosmos/styles/utilities/text/variations/grays.css';
import '@cosmos/styles/utilities/text/variations/primary.css';
import '@cosmos/styles/utilities/text/variations/brand-primary.css';
import '@cosmos/styles/utilities/text/variations/green.css';
import '@cosmos/styles/utilities/text/variations/yellow.css';
import '@cosmos/styles/utilities/background/variations/grays.css';
import '@cosmos/styles/utilities/background/variations/blue.css';
import '@cosmos/styles/utilities/float/float.css';
import '@cosmos/styles/utilities/overflow/overflow.css';
