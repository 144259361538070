/* eslint-disable no-use-before-define */

import { useState, createContext, useContext } from 'react';
import { isBlackNovember } from 'utils/campaigns';
import config from '../../../../../env.config';
import { seasonInfo } from '../../../../constants';
import SearchService from '../../../../@marketplace/service';
import { QUERY_IDS } from '@core/data/constants/query-ids';

/* First we will make a new context */
export const MarketplaceContext = createContext();

/* Then create a provider Component */
function MarketplaceProvider(props) {
  const defaultResultsPage = {
    requested: null,
    loading: false,
    size: config.envConfig.RESULTS.QTY_PER_PAGE,
    searchText: null,
    couponType: null,
    groupKeys: null,
    formats: [],
    optionsPayment: [],
    categories: [],
    topifications: [],
    topics: [],
    ratings: [],
    languages: [],
    searchAfterScore: null,
    searchAfterProductId: null,
    sort: '',
    lng: 'en',
    totalProducts: 0,
    allProducts: []
  };

  const information = {
    menu: {
      position: 'left',
      opened: false,
      submenu: false,
      changeLanguage: false,
      search: false
    },
    showSearch: true,
    isBlackNovemberActive: isBlackNovember(),
    query: { ...props?.pageProps?.query },
    featureSwitch: {},
    loadingPrice: false,
    showNewProductCard: false,
    showOrdenationFilter: true,
    isModalOpen: false,
    marketplace: {
      searchPage: {
        banner: {},
        bestSellers: [],
        categoryProducts: [],
        categories: [],
        newbies: [],
        producers: [],
        centralBanner: { image: {}, title: {}, description: {}, link: {} },
        allProducts: []
      },
      // pagina de resultado global
      resultsPage: { ...defaultResultsPage }, // clonando default para nao manter referencias
      resultsRelatedPage: { ...defaultResultsPage }
    },
    actions: {
      setShowSearch: showSearch => setShowSearch(showSearch),
      setSearchPage: data => setSearchPage(data),
      setResultsPage: data => setResultsPage(data),
      setResultsRelatedPage: data => setResultsRelatedPage(data),
      clearResultsPage: () => clearResultsPage(),
      appendResults: data => appendResults(data),
      formatDataForResultsPage: data => formatDataForResultsPage(data),
      loadMoreResultsPage: data => loadMoreResultsPage(data),
      applyFilterResultsPage: (data, useQualityScoreQueries) =>
        applyFilterResultsPage(data, useQualityScoreQueries),
      applyFilterRelatedResultsPage: data =>
        applyFilterRelatedResultsPage(data),
      setQueryParams: query => setQueryParams(query),
      setFeatureSwitch: featureSwitch => setFeatureSwitch(featureSwitch),
      setLoadingPrice: loading => setLoadingPrice(loading),
      setIsModalOpen: isModalOpen => setIsModalOpen(isModalOpen)
      // setUpdateSearch: updateSearch => setUpdateSearch(updateSearch) // setUpdateSearch not defined
    },
    changeState: (property, value) => {
      setInfo(prevState => ({
        ...prevState,
        [property]: value
      }));
    }
  };

  const [info, setInfo] = useState(information);
  const queryId = QUERY_IDS.PRINCIPAL_QUERY

  const setShowSearch = showSearch => {
    info.showSearch = showSearch;
    info.changeState('showSearch', showSearch);
  };

  const setSearchPage = data => {
    information.marketplace.togglz = data.togglz;
    information.marketplace.searchPage = { ...data };
    information.changeState('marketplace', information.marketplace);
  };

  const formatDataForResultsPage = data => {
    const {
      requestDataGroupByCategory: groupByCategory = []
    } = information.marketplace.resultsPage;

    const { requestData } = data;
    let { requestDataGroupByCategory } = data;
    if (!requestDataGroupByCategory) {
      requestDataGroupByCategory = groupByCategory;
    }

    // produtos resultados
    const results = (requestData && requestData.results) || [];

    // ultimo produto do resultados
    const lastProduct = results[results.length - 1];

    // pegando searchAfter do ultimo produto
    let searchAfterScore;
    let searchAfterProductId;
    if (lastProduct && lastProduct.searchAfter) {
      [searchAfterScore, searchAfterProductId] = lastProduct.searchAfter;
    }

    // montando o objeto que sera enviado no contexto
    const result = {
      couponType: data.couponType || null,
      groupKeys: data.groupKeys || null,
      campaigns: data.campaigns || null,
      searchText: data.searchText || null,
      formats: data.formats || [],
      optionsPayment: data.optionsPayment || [],
      categories: data.categories || [],
      topifications: data.topifications || [],
      topics: data.topics || [],
      ratings: data.ratings || [],
      languages: data.languages || [],
      coupons: data.coupons || data?.discounts || [],
      size: requestData?.paging?.size || config.envConfig.RESULTS.QTY_PER_PAGE,
      searchAfterScore: searchAfterScore || null,
      searchAfterProductId: searchAfterProductId || null,
      totalProducts: requestData?.paging?.total || 0,
      lng: data.lng,
      allProducts: results,
      loading: data.loading,
      sort: data.sort || '',
      origin: requestData?.origin || '',
      filterCount: data?.filterCount || []
    };

    if (requestDataGroupByCategory) {
      result.requestDataGroupByCategory = requestDataGroupByCategory;
    }

    return result;
  };

  const setResultsPage = (data = {}) => {
    // montando o objeto que sera enviado no contexto
    information.marketplace.resultsPage = {
      loading: false,
      requested: true,
      ...data
    };

    // alterando dados no contexto
    information.changeState('marketplace', information.marketplace);
  };

  const setResultsRelatedPage = (data = {}) => {
    // montando o objeto que sera enviado no contexto
    information.marketplace.resultsRelatedPage = {
      loading: false,
      requested: true,
      ...data
    };

    // alterando dados no contexto
    information.changeState('marketplace', information.marketplace);
  };

  const appendResults = (data = {}) => {
    const { requestData } = data;

    // produtos resultados
    const results = requestData?.results || [];

    // ultimo produto do resultados
    const lastProduct = results[results.length - 1];

    // pegando searchAfter do ultimo produto
    let searchAfterScore;
    let searchAfterProductId;
    if (lastProduct && lastProduct?.searchAfter) {
      [searchAfterScore, searchAfterProductId] = lastProduct.searchAfter;
    }

    information.marketplace.resultsPage = {
      ...information.marketplace.resultsPage,
      // adicionando items ao final da lista
      allProducts: [
        ...information.marketplace.resultsPage.allProducts,
        ...results
      ],

      loading: false,
      requested: true,

      // atualizando dados de
      searchAfterScore: searchAfterScore || null,
      searchAfterProductId: searchAfterProductId || null
    };

    information.changeState('marketplace', information.marketplace);
  };

  // limpa os resultados de busca
  const clearResultsPage = () => {
    information.marketplace.resultsPage = { ...defaultResultsPage };
    information.changeState('marketplace', information.marketplace);
  };

  // permite alterar o loading da pagina de resultados
  const setLoading = isLoading => {
    information.marketplace.resultsPage = {
      ...information.marketplace.resultsPage,
      loading: isLoading
    };
    information.changeState('marketplace', information.marketplace);
  };

  // carrega mais items da mesma pesquisa
  const loadMoreResultsPage = async data => {
    const params = new URLSearchParams(window.location.search)

    // mostra o loading
    setLoading(true);

    // dados originais da pesquisa com o searchAfter devidamente configurado
    const {
      allProducts,
      categories,
      topifications,
      topics,
      formats,
      optionsPayment,
      ratings,
      languages,
      coupons,
      campaigns,
      size,
      searchText,
      lng,
      searchAfterScore,
      searchAfterProductId,
      couponType,
      groupKeys,
      sort
    } = information.marketplace.resultsPage;

    const offsetTotal =
      allProducts?.length / config.envConfig.RESULTS.QTY_PER_PAGE;

    // faz a requisição mantendo os dados originais
    const resultsData = await SearchService.loadResultsProduct({
      categories,
      topifications,
      topics,
      formats,
      optionsPayment,
      ratings,
      languages,
      coupons,
      campaigns,
      size,
      searchText,
      lng,
      couponType,
      groupKeys,
      searchAfterScore,
      sort,
      queryId,
      searchAfterProductId,
      useQualityScoreQueries: data || null,
      offset: offsetTotal || 0
    });
    appendResults(resultsData);
    return resultsData;
  };

  // aplica filtros e reinicia a pesquisa
  const applyFilterResultsPage = async (
    data,
    useQualityScoreQueries = false
  ) => {

    const params = new URLSearchParams(window.location.search)

    // mostra o loading
    setLoading(true);

    // dados originais da pesquisa com o searchAfter devidamente configurado
    const {
      size,
      searchText,
      lng,
      couponType,
      campaigns,
      groupKeys,
      sort
    } = information.marketplace.resultsPage;
    let filterByCampaign = campaigns;
    let filterByCoupon = couponType;

    if (data?.filterByCoupon) {
      filterByCampaign = null;

      if (data?.coupons?.length) {
        filterByCoupon = seasonInfo.COUPON_TYPE;
      } else {
        filterByCampaign = seasonInfo.COUPON_TYPE;
        filterByCoupon = null;
      }
    } else {
      filterByCoupon = null;
    }

    // faz a requisição mantendo os dados originais
    const resultsData = await SearchService.loadResultsProduct({
      size,
      searchText,
      lng,
      couponType: filterByCoupon,
      campaigns: filterByCampaign,
      groupKeys,
      loadGroupByCategory: true,
      useQualityScoreQueries,
      searchAfterScore: null,
      searchAfterProductId: null,
      sort,
      queryId,
      ...data
    });

    const formattedData = formatDataForResultsPage(resultsData);
    formattedData.sort = formattedData.sort || sort;
    setResultsPage(formattedData);
    return resultsData;
  };

  const applyFilterRelatedResultsPage = async data => {
    setLoading(true);

    const {
      size,
      lng,
      couponType,
      sort,
      groupKeys
    } = information.marketplace.resultsPage;

    const {
      marketplace: { resultsPage = {} }
    } = information;

    const productsRelated = await SearchService.loadResultsProduct({
      size,
      lng,
      couponType,
      groupKeys,
      loadGroupByCategory: true,
      searchAfterScore: null,
      searchAfterProductId: null,
      sort,
      ...data
    });

    const resultsData = {
      ...resultsPage,
      ...data,
      loading: false,
      requested: true
    };

    const formattedDataRelated = formatDataForResultsPage(productsRelated);
    setResultsRelatedPage(formattedDataRelated);
    setResultsPage(resultsData);
  };

  const setFeatureSwitch = featureSwitch => {
    information.featureSwitch = featureSwitch;
    information.changeState('featureSwitch', information.featureSwitch);
  };

  const setQueryParams = query => {
    information.query = query;
    information.changeState('query', information.query);
  };

  const setLoadingPrice = loading => {
    information.loadingPrice = loading;
    information.changeState('loadingPrice', information.loadingPrice);
  };

  const setIsModalOpen = isModalOpen => {
    information.isModalOpen = isModalOpen;
    information.changeState('isModalOpen', information.isModalOpen);
  };

  return (
    <MarketplaceContext.Provider value={info}>
      {props.children}
    </MarketplaceContext.Provider>
  );
}

/* then make a consumer which will surface it */
const MarketplaceConsumer = MarketplaceProvider.Consumer;
MarketplaceProvider.defaultProps = {
  query: {}
};
export default MarketplaceProvider;

export function useMarketplaceContext() {
  const context = useContext(MarketplaceContext);
  if (context === undefined) {
    throw new Error(
      'useMarketplaceContext must be used within a MarketplaceContext.Provider'
    );
  }
  return context;
}

export { MarketplaceConsumer };
